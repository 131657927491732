<template>
    <div class="layout">
        <Header :menus="menus"></Header>
        <div class="page-section">
            <div class="banner document-banner">
                <img src="../assets/doc/banner.png" style="height:150px" />
                <div class="banner-search" style="top:20px;">
                    <input
                        class="banner-search-input"
                        placeholder="请输入搜索关键字"
                    />
                </div>
            </div>
            <div class="container container-detail">
                <div class="container-wrap detail-wrap">
                    <ul class="detail-menu">
                        <li>
                            <div class="detail-menu-main">
                                <img src="../assets/icon/service.png" alt="" />
                                <span>售后政策</span>
                            </div>
                            <ul class="detail-menu-sub">
                                <li>
                                    保修范围
                                </li>
                                <li>
                                    非保修范围
                                </li>
                            </ul>
                        </li>
                        <li>
                            <div class="detail-menu-main">
                                <img src="../assets/icon/match.png" alt="" />
                                <span>固件匹配</span>
                            </div>
                            <ul class="detail-menu-sub">
                                <li>
                                    固件版本对照表
                                </li>
                            </ul>
                        </li>
                        <li>
                            <div class="detail-menu-main">
                                <img src="../assets/icon/jianrong.png" alt="" />
                                <span>兼容问题</span>
                            </div>
                            <ul class="detail-menu-sub">
                                <li>
                                    驱动兼容
                                </li>
                            </ul>
                        </li>
                        <li>
                            <div class="detail-menu-main">
                                <img
                                    src="../assets/icon/waishechajian.png"
                                    alt=""
                                />
                                <span>其他问题</span>
                            </div>
                        </li>
                    </ul>
                    <div class="detail-content">
                        <div class="detail-content-bread">
                            <span
                                ><a href="">常见问题</a>>
                                <a href="">售后政策 </a> >
                                <a href=""> 保修范围 </a></span
                            >
                        </div>
                        <div class="detail-content-text">
                            <div class="detail-content-text-left">
                                <p>
                                    三包是零售商业企业对所售商品实行”包修包换、包退”的简称。指商品进入消费领域后，卖方对买方所购物品负责而
                                    采取的在一定限期内的一种信用保证办法。对不是因用户使用、保管不当，而属于产品质量问题而发生的故障提供该项服务。
                                </p>

                                <p>
                                    <strong>1三包产品</strong>
                                </p>

                                <p>
                                    第一批实施三包的部分产品共18种:自行车、彩电、黑白电视、家用录像机、摄像机、收录机、电子琴、家用电冰箱、
                                    洗衣机、电风扇、微波炉、吸尘器、家用空调器、吸排油烟机、燃气热水器、缝纫机、钟表、摩托车。
                                </p>

                                <p>
                                    新三包规定中明确，实行三包的产品目录将由国务院有关部门定和调整。
                                </p>

                                <p>
                                    随着移动电话、固定电话、微型计算机、家用视听产品等四类新产品的加入，目前我国共有22种产品
                                    纳入“三包"政策范围之内。
                                </p>

                                <p>进口产品同样适用于新三包规定。</p>

                                <p>
                                    未纳入新三包规定的产品，出现了质量问题，销售者均应依法负责修理、更换、退货并赔偿由此而受
                                    到的损失。
                                </p>

                                <p>
                                    <b>2三包责任</b>
                                </p>

                                <p>
                                    消费者购买的产品出现以下情况，有权要求经销者承担三包责任。
                                </p>

                                <p>
                                    1不具备产品应当具备的使用性能，而事先没有说明的;
                                </p>
                            </div>
                            <div class="detail-content-text-right">
                                <ul>
                                    <li class="active">
                                        三包产品
                                    </li>
                                    <li>三包责任</li>
                                    <li>三包时间</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container container-experience">
                <div class="container-wrap">
                    <div class="experience-title">
                        免费试用名额等你来拿，赶紧注册吧！
                    </div>
                    <ul class="experience-list">
                        <li>
                            <div class="experience-dot"></div>
                            行业主板
                        </li>
                        <li>
                            <div class="experience-dot"></div>
                            安卓智慧屏
                        </li>
                        <li>
                            <div class="experience-dot"></div>
                            WIF-OS
                        </li>
                    </ul>
                    <div class="experience-btn">
                        立即体验
                    </div>
                </div>
            </div>
        </div>
        <DocFooter />
    </div>
</template>

<script>
import { Header, DocFooter } from "@components";

export default {
    name: "App",
    components: {
        Header,
        DocFooter
    },
    data() {
        return {
            menus: [
                {
                    text: "Wif.ink",
                    id: "app"
                },
                {
                    text: "开发者社区",
                    id: "develop"
                }
            ],
            listData: [
                {
                    title: "开发文档",
                    subTitle: "查看开发文档详情",
                    imgSrc: require("../assets/doc/develop.png")
                },
                {
                    title: "FAQ",
                    subTitle: "常见问题回答",
                    imgSrc: require("../assets/doc/question.png")
                }
            ],
            downloadList: [
                {
                    title: "产品文档",
                    subTitle: "查看在线文档",
                    imgSrc: require("../assets/doc/document.png")
                },
                {
                    title: "SDK 下载",
                    subTitle: "物联网管理SDK下载",
                    imgSrc: require("../assets/doc/xiazai.png")
                },
                {
                    title: "WIF-OS下载",
                    subTitle: "网飞安卓系统下载",
                    imgSrc: require("../assets/doc/download.png")
                },
                {
                    title: "社区中心",
                    subTitle: "前往开发者社区",
                    imgSrc: require("../assets/doc/shequ.png")
                }
            ]
        };
    }
};
</script>
